<template>
  <div>
    <div class="search">
      <el-page-header @back="$router.go(-1)" content="赠卡列表"> </el-page-header>
      <br />
      <el-form :inline="true" class="top">
        <!-- <el-form-item label="昵称:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <!-- <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button> -->
          <el-button type="primary" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <!-- <el-table-column type="index" width="50" align="center"> </el-table-column> -->
      <el-table-column prop="levelTitle" label="赠送等级" align="center"> </el-table-column>
 
      <el-table-column prop="shareNum" label="赠送次数" align="center"> </el-table-column>
      <el-table-column prop="addTime" label="添加时间" align="center"> </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          > -->
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="添加/编辑"
      :visible.sync="dialogVisible"
      width="30%"
   
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="赠送等级:">
          <el-select v-model="addList.levelId" placeholder="请选择">
            <el-option
              v-for="item in userLevel"
              :key="item.levelId"
              :label="item.title"
              :value="item.levelId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赠送次数:">
            <el-input-number v-model="addList.shareNum"  :min="0" :max="1000000" label="赠送次数"></el-input-number>
          <!-- <el-input v-model="addList.shareNum"></el-input> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { receiveList, receiveListAdd } from "../../api/vip.js";
import { userLevelList } from "../../api/level";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        fatherId: 0,
        // nickName: "",
        // phone: "",
        // beginTime: "",
        // endTime: "",
      },
      addList: {
        setId: null,
        levelId: null,
        fatherId: 0,
        shareNum: 0,
      },
      addRules: {
        tableData: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      userLevel: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.params.fatherId = this.$route.query.fatherId || 0;
    this.addList.fatherId = this.$route.query.fatherId || 0;
    this.getUserLevelList();
    this.getList();
  },
  methods: {
    edit(row){
        this.addList.levelId = row.levelId
        this.addList.fatherId =  row.fatherId
        this.addList.setId =  row.setId
        this.addList.shareNum =  row.shareNum
        this.dialogVisible = true
    },
    async getUserLevelList() {
      const { data } = await userLevelList({
        currentPage: 1,
        pageSize: 15,
      });
      console.log(data);

      this.userLevel = data.list;
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await receiveList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    add() {
        this.addList.levelId = null
        this.addList.shareNum = 0
        this.dialogVisible = true
    },
    async submit(){
        if(!this.addList.levelId ){
            return this.$message.warning('请完善内容')
        }
       const {data} = await receiveListAdd(this.addList)
       if(data.code==0){
        this.$message.success('添加成功')
        this.dialogVisible = false
        this.getList()
       }else{
        this.$message.warning(data.msg)
       }
    }
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
